/** @jsxImportSource theme-ui */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Box, Container, Text, Heading } from "theme-ui";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ThemedMarkdown from "../components/themed-markdown";
import { format } from "date-fns";

type BlogPostProps = {
  data: {
    markdownRemark: any;
  };
  location: string;
};

export const BlogPost = ({ data, location }: BlogPostProps) => {
  const { frontmatter } = data.markdownRemark;
  const imageData = getImage(frontmatter.featuredimage);
  return (
    <Layout
      activeNav="wissenwertes"
      seo={{
        title: frontmatter.title,
        pathname: location,
        noindex: true,
      }}
    >
      <Container my={5}>
        <Box>
          <Heading as="h2" variant="styles.h2">
            {frontmatter.title}
          </Heading>
          <Text variant="smallText">
            {format(new Date(Date.parse(frontmatter.date)), "dd.MM.yyyy")}
          </Text>
          {imageData && (
            <Box mt={3}>
              <GatsbyImage image={imageData} alt={frontmatter?.featuredimagealt || ""} />
            </Box>
          )}
          <ThemedMarkdown sx={{ flex: "2 2 0" }} html={frontmatter.body} />
        </Box>
      </Container>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        date
        featuredimage {
          childImageSharp {
            gatsbyImageData(width: 1280, quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        featuredimagealt
        body
      }
    }
  }
`;
